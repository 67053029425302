import './ContentTitle.scss';

import React from 'react';

type ContentTitleProps = {
    fileCount: number;
    renderItem?: null | (() => React.ReactElement);
    oneToOne: boolean;
    setOneToOne?: (value: boolean) => void;
};

const ContentTitle = ({
    fileCount,
    renderItem,
    oneToOne,
    setOneToOne,
}: ContentTitleProps) => {
    const changeOneToOne = () => {
        if (setOneToOne) setOneToOne(!oneToOne);
    };

    return (
        <div className="process-title">
            <div className="process-title-info">
                <h3>
                    {fileCount} file{fileCount > 1 ? 's' : ''} selected
                </h3>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="oneToOneCheckbox"
                        checked={oneToOne}
                        onChange={changeOneToOne}
                        disabled={setOneToOne ? false : true}
                    />
                    <label
                        className="form-check-label"
                        htmlFor="oneToOneCheckbox"
                    >
                        Output one POD5 file per FAST5 file
                    </label>
                </div>
            </div>
            {renderItem && renderItem()}
        </div>
    );
};

export default ContentTitle;
