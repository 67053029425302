import './ConversionDetails.scss';

import ContentTitle from '@/components/molecules/ContentTitle';
import HomeLink from '@/components/atoms/HomeLink';
import ProgressBar from '@/components/molecules/ProgressBar';

type ConversionDetailsProps = {
    fileCount: number;
    isError: boolean;
    status: status;
    percentage: number;
    oneToOne: boolean;
};

const ConversionDetails = ({
    fileCount,
    isError,
    status,
    percentage,
    oneToOne,
}: ConversionDetailsProps) => {
    const { filesStarted, filesEnded, readCount, readsProcessed, sampleCount } =
        status;
    const isCompleted = filesEnded === fileCount;

    let statusText = 'Conversion in progress...';
    if (isCompleted) statusText = 'Conversion completed';
    if (isError) statusText = 'Error';

    const titleRenderItem =
        isCompleted || isError
            ? () => <HomeLink text="Convert more..." />
            : null;

    return (
        <div className="progress-details">
            <ContentTitle
                fileCount={fileCount}
                renderItem={titleRenderItem}
                oneToOne={oneToOne}
            />

            <div className="progress-details__wrapper">
                <p className="progress-details__title">Progress completed</p>
                <ProgressBar percent={percentage} error={isError} />

                <p className="progress-details__title">Status</p>
                <p>{statusText}</p>

                <p className="progress-details__title">File progress</p>
                <p>
                    {filesStarted} / {fileCount}
                </p>

                <p className="progress-details__title">Reads converted</p>
                <p>
                    {readsProcessed?.toLocaleString()} /{' '}
                    {readCount?.toLocaleString()}
                </p>

                <p className="progress-details__title">Samples converted</p>
                <p>{sampleCount?.toLocaleString()}</p>
            </div>
        </div>
    );
};

export default ConversionDetails;
