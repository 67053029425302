import './Header.scss';

import { Navbar } from 'reactstrap';
import logo from '@/assets/ont-logo.svg';

const Header = () => {
    return (
        <header>
            <Navbar light className="limit-width">
                <a href={`/`}>
                    <img
                        src={logo}
                        className="navbar__logo"
                        alt="Oxford Nanopore Technologies logo"
                    />
                </a>
                <h1 className="navbar__title">FAST5 to POD5</h1>
            </Navbar>
        </header>
    );
};

export default Header;
