import { Container, Col, Row } from "reactstrap";

import "./Footer.scss";

const Footer = () => {
  return (
    <Container fluid className="px-0 mt-6">
      <Row>
        <Col>
          <footer className="footer">
            <div className="footer__text">© All Rights Reserved by ONT</div>
          </footer>
        </Col>
      </Row>
    </Container>
  );
};
export default Footer;
