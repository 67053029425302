import './Dropzone.scss';

import classNames from 'classnames';
import folder from '@/assets/folder.svg';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

type DropzoneProps = {
    onDropped: (files: File[]) => void;
    fileCount: number;
};

const Dropzone = ({ onDropped, fileCount }: DropzoneProps) => {
    const onDrop = useCallback((acceptedFiles: File[]) => {
        onDropped(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: ['.fast5'],
        multiple: true,
    });

    const dropzoneClasses = classNames({
        'dropzone': true,
        'dropzone-active': isDragActive,
        'dropzone--small': fileCount > 0,
    });

    const dropTextMain =
        fileCount > 0 ? 'Drop more .fast5 files' : 'Drag and drop .fast5 files';

    const dropText = isDragActive ? 'Release to add' : dropTextMain;

    return (
        <div className={dropzoneClasses} {...getRootProps()}>
            <input className="dropzone-input" {...getInputProps()} />
            <div className="dropzone-content">
                <img src={folder} className="mb-2" role="presentation" />
                <p className="dropzone-text">
                    {dropText}
                    {!isDragActive && (
                        <span className="mt-1">or click to browse</span>
                    )}
                </p>
            </div>
        </div>
    );
};

export default Dropzone;
