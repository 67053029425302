import './IntroText.scss';

const IntroText = () => (
    <>
        <h2>Convert FAST5 files to POD5</h2>
        <p>
            Everything happens locally on your machine. No data leaves your
            device.
        </p>
    </>
);

export default IntroText;
