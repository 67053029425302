import './Dashboard.scss';

import * as Comlink from 'comlink';

import { useEffect, useState } from 'react';

import ConversionDetails from '@/components/organisms/ConversionDetails';
import type { Converter } from '../../../workers/converter';
import Dropzone from '@/components/organisms/Dropzone';
import ErrorModal from '@/components/molecules/ErrorModal';
import FileDetails from '@/components/organisms/FileDetails';
import IntroText from '@/components/atoms/IntroText';
import NotSupportedModal from '@/components/molecules/NotSupportedModal';
import { downloadFile } from '@/lib/utils';
import { isSafari } from 'react-device-detect';

const wasmWorker = Comlink.wrap<typeof Converter>(
    new Worker('../../../workers/converter.ts', { type: 'module' })
);

let workerInstance: Converter;

const Dashboard = () => {
    const [selectedFiles, setSelectedFiles] = useState<Array<File>>([]);
    const [oneToOne, setOneToOne] = useState(false);
    const [isConverting, setConversionStarted] = useState(false);
    const [errors, setErrors] = useState<Error[]>([]);
    const [modalStatus, setModalStatus] = useState(false);
    const [assetsLoading, setAssetsLoading] = useState(true);
    const [percentage, setPercentage] = useState(0);

    const [status, setStatus] = useState<status>({
        filesStarted: 0,
        filesEnded: 0,
        readCount: 0,
        readsProcessed: 0,
        sampleCount: 0,
        currentFileReadTotal: 0,
    });

    const fileCount = selectedFiles?.length ?? 0;

    const changeHandler = (files: File[]) => {
        setSelectedFiles((existing) => {
            // drop duplicates
            const alreadyAdded = existing
                .map((e) => e.name)
                .filter((e) => files.map((n) => n.name).includes(e));

            if (alreadyAdded.length > 0) {
                files = files.filter((x) => !alreadyAdded.includes(x.name));
            }

            return [...existing, ...files];
        });
    };

    const updateStatus = (status: status) => {
        setStatus(status);

        const totalFiles = selectedFiles.length;
        const {
            filesStarted,
            filesEnded,
            readCount,
            readsProcessed,
            currentFileReadTotal,
        } = status;
        let percentage = (100 / selectedFiles.length) * filesEnded;

        // if we are part way through a file, add the percentage on
        if (filesStarted > filesEnded && totalFiles > 0) {
            const fileRemainingReads = readCount - readsProcessed;
            const fileProgress = currentFileReadTotal - fileRemainingReads;
            const filePercent = (fileProgress / currentFileReadTotal) * 100;
            const oneFileWorth = 1 / totalFiles;
            percentage += filePercent * oneFileWorth;
        }

        setPercentage(percentage);
        setStatus(status);
    };

    const handleSubmission = async () => {
        setConversionStarted(true);

        const { errors } = await workerInstance.main(
            selectedFiles,
            oneToOne,
            Comlink.proxy(downloadFile),
            Comlink.proxy(updateStatus)
        );

        if (errors.length > 0) {
            setErrors(errors);
            setModalStatus(true);
        }
    };

    const isError = errors.length > 0;

    useEffect(() => {
        if (isSafari) return;

        const initialiseWasmModule = async () => {
            workerInstance = await new wasmWorker();
            await workerInstance.wasmInit();
            setAssetsLoading(false);
        };
        initialiseWasmModule();
    }, []);

    return (
        <div className="dashboard">
            {isSafari && <NotSupportedModal />}

            {<IntroText />}

            {!isConverting && (
                <Dropzone onDropped={changeHandler} fileCount={fileCount} />
            )}

            {selectedFiles.length > 0 && !isConverting && (
                <FileDetails
                    files={selectedFiles}
                    onClick={handleSubmission}
                    assetsLoading={assetsLoading}
                    oneToOne={oneToOne}
                    setOneToOne={setOneToOne}
                />
            )}

            {isConverting && (
                <ConversionDetails
                    fileCount={selectedFiles.length}
                    isError={isError}
                    status={status}
                    percentage={percentage}
                    oneToOne={oneToOne}
                />
            )}

            <ErrorModal
                isOpen={modalStatus}
                closeModal={() => setModalStatus(false)}
                errors={errors}
            />
        </div>
    );
};

export default Dashboard;
