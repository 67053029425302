import "./ActionButton.scss";

type ActionButtonProps = {
  classname?: string;
  onClick?: () => void;
  text?: string;
  icon?: string | null;
  disabled: boolean;
};

const ActionButton = ({
  classname,
  onClick,
  text,
  icon,
  disabled = false,
}: ActionButtonProps) => (
  <button
    className={`actionbutton ${classname}`}
    onClick={onClick}
    disabled={disabled}
  >
    {text}
    {icon && <img src={icon} className="ms-2" role="presentation" />}
  </button>
);

export default ActionButton;
