import './FileDetails.scss';

import ActionButton from '@/components/atoms/ActionButton';
import ContentTitle from '@/components/molecules/ContentTitle';
import classNames from 'classnames';
import { formatBytes } from '@/lib/utils';
import { isSafari } from 'react-device-detect';
import play from '@/assets/play.svg';

type FileDetailsProps = {
    files: File[];
    onClick: () => void;
    assetsLoading: boolean;
    oneToOne: boolean;
    setOneToOne: (value: boolean) => void;
};

const FileDetails = ({
    files,
    onClick,
    assetsLoading,
    oneToOne,
    setOneToOne,
}: FileDetailsProps) => {
    let buttonText = 'Start';
    let buttonDisabled = false;

    if (assetsLoading) {
        buttonText = isSafari
            ? 'Unsupported browser'
            : 'Waiting for assets to load...';
        buttonDisabled = true;
    }

    const buttonClasses = classNames({
        'proceed-button': !assetsLoading,
        'disabled-button': assetsLoading,
    });

    return (
        <div className="file-details">
            <ContentTitle
                fileCount={files.length}
                oneToOne={oneToOne}
                setOneToOne={setOneToOne}
                renderItem={() => (
                    <ActionButton
                        classname={buttonClasses}
                        onClick={onClick}
                        text={buttonText}
                        icon={play}
                        disabled={buttonDisabled}
                    />
                )}
            />

            <div className="file-details__file-wrapper">
                {files.map(({ name, size, lastModified }) => {
                    const date = new Date(lastModified).toLocaleDateString();
                    return (
                        <div key={name} className="file-details__file">
                            <div>
                                <p className="file-details__file__title">
                                    {name}
                                </p>
                                <p>File size: {formatBytes(size)}</p>
                                <p>Last modified date: {date}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default FileDetails;
