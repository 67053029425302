import './ErrorModal.scss';

import ActionButton from '@/components/atoms/ActionButton';
import Modal from 'react-modal';

type ErrorModalProps = {
    closeModal: () => void;
    isOpen: boolean;
    errors: Error[];
};

const ErrorModal = ({ closeModal, isOpen, errors }: ErrorModalProps) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            className="modal-container error-modal"
            overlayClassName="modal-overlay"
            // @ts-ignore
            appElement={document.querySelector('#root')}
        >
            <h2 className="font-bold">An error occured</h2>

            <ul>
                {errors.map((error, i) => (
                    <li key={i}>{error.message}</li>
                ))}
            </ul>

            <div className="text-center">
                <ActionButton
                    classname="close-button"
                    onClick={closeModal}
                    text="Close"
                    disabled={false}
                />
            </div>
        </Modal>
    );
};

export default ErrorModal;
