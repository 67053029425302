import './themes/index.scss';

import Dashboard from '@/components/organisms/Dashboard';
import Footer from '@/components/molecules/Footer';
import Header from '@/components/molecules/Header';

function App() {
    return (
        <div className="page">
            <Header />
            <main className="limit-width">
                <Dashboard />
            </main>
            <Footer />
        </div>
    );
}

export default App;
