export function decode_utf8(value: ArrayBuffer | Uint8Array): string {
    const decoder = new TextDecoder('utf-8');
    return decoder.decode(value);
}

globalThis.utils = { decode_utf8: decode_utf8 }; // TODO remove this line later on

export function encode(value: string): Uint8Array {
    const decoder = new TextEncoder();
    return decoder.encode(value);
}

/**
 * Removes the \u0000 from the given string. Which is the Unicode Character 'NULL' (U+0000)
 *
 * @param str
 * @returns
 */
export function trimNull(str: string): string {
    // eslint-disable-next-line no-debugger
    return str.replace(/\0/g, '');
}

export function formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const downloadFile = ({ name, url }: { name: string; url: string }) => {
    const a = document.createElement('a');
    a.download = name;
    a.href = url;
    a.style.display = 'none';

    document.body.appendChild(a);
    a.click();

    setTimeout(() => {
        document.body.removeChild(a);
        URL.revokeObjectURL(a.href);
    }, 2000);
};
