import './NotSupportedModal.scss';

import ActionButton from '@/components/atoms/ActionButton';
import Modal from 'react-modal';
import { useState } from 'react';

const NotSupportedModal = () => {
  const [modalIsOpen, setIsOpen] = useState(true);

  function closeModal() {
    setIsOpen(false);
  }
  
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={false}
      className="modal-container not-supported-modal"
      appElement={document.querySelector('#root')}
    >
      
      <h2 className='font-bold'>Incompatible browser detected</h2>
      <p><strong>Safari is not currently supported.</strong></p>
      <p>Google Chrome or Microsoft Edge are recommended.</p>
      
      <div className="text-center">
        <ActionButton classname="close-button" onClick={closeModal} text="Close" />
      </div>
    </Modal>
  );
};

export default NotSupportedModal;
