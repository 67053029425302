import "./HomeLink.scss";

type HomeLinknProps = {
  text: string;
};

const HomeLink = ({
  text,
}: HomeLinknProps) => <a className={`home-link`} href="/">{text}</a>;

export default HomeLink;
