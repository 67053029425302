import "./ProgressBar.scss";

import { Progress } from "reactstrap";
import classNames from "classnames";

type ProgressBarProps = {
  error?: boolean;
  percent: number;
};

const ProgressBar = ({ error = false, percent }: ProgressBarProps) => {
  const progressClasses = classNames({
    "progress-bar__multi__success": !error,
    "progress-bar__multi__error": error,
  });

  return (
    <div className="progress-bar progress-completed">
      <Progress multi className="progress-bar__multi">
        <Progress bar className={progressClasses} value={percent} aria-label="Progress completed bar">
          {Math.floor(percent)}%
        </Progress>
      </Progress>
    </div>
  );
};

export default ProgressBar;
